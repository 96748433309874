#desktop-basket {
    display: none;
    width: 22%;
    background-color: #1F2233;
    position: fixed;
    right: 0;
    max-height: calc(100vh - 51px);
    min-height: calc(100vh - 51px);
    z-index: 1;
    top: 61px;
    overflow: auto;
}

@media only screen and (min-width: 1100px) {
    #desktop-basket {
        width: 17.5%;
    }
}