.menu {
    position: absolute;
    background-color: #181A26;
    color: white;
    min-height: 200vh;
    min-width: 45%;
    z-index: 99;
    display: none;
}

.menu-option {
    display: flex;
    flex-wrap: wrap;
}

.menu-heading {
    padding-left: 15%;
    font-weight: 600;
}

.menu-heading:hover {
    cursor: pointer;
}

.menu-sub-headings {
    display: none;
    width: 100%;
    background-color: #262938;
}

.menu-sub-headings p {
    text-align: center;
    width: 100%;
    font-weight: 700;
}

.menu-option p {
    font-size: 13px;
}

.menu-option:hover {
    background-image: linear-gradient(#18243E, #2693AA);
}

.menu-actions {
    position: fixed;
    bottom: 2.5%;
    margin-left: 5%;
    min-width: 45%;
}

#matches-subheading div:hover {
    cursor: pointer;
}

.menu-actions p {
    font-size: 11px;
    font-weight: bold;
}

.menu-actions div {
    display: flex;
    margin-left: 8%;
    justify-content: space-between;
    width: 70%;
}

.menu-gold-coin {
    height: 15px;
    width: 15px;
    margin-right: 2.5%;
}

.greyed {
    color: gray;
}

.greyed img {
    filter: grayscale(1);
}

.flag {
    max-width: 30px;
    max-height: 15px;
}

#CWC {
    color: #03EC43;
}

[id='Civ FR'] {
    color: #E6920F;
}

#CPL {
    color: #902319;
}

#Others {
    color: #0C62D0;
}

#Others, #CPL, [id="Civ FR"], #CWC, #All {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 75%;
    margin-left: 25%;
}


#Others:hover, #CPL:hover, [id="Civ FR"]:hover, #CWC:hover, #All:hover {
    cursor: pointer;
}

.category-logos {
    display: initial;
    max-width: 25px;
    max-height: 25px;
    margin-right: 8%;
}

#cpl-category, #others-category, #cwc-category, #civ-category, #all-category {
    display: initial;
    width: fit-content;
    text-align: center;
    font-size: 14px;
}

#menu-balance-refill {
    display: flex;
    align-items: center;
    margin-left: 8%;
    cursor: pointer;
}

#menu-balance-reset {
    display: flex;
    align-items: center;
    margin-left: 8%;
    cursor: pointer;
}

#english-button-lang, #french-button-lang {
    cursor: pointer;
}

#french-flag {
    max-width: 20px;
}

#uk-flag {
    max-width: 22px;
}

#civ-bets-arrow {
    max-width: 10px;
    margin-left: 5px;
}

@media only screen and (min-width: 600px) {
    .menu {
        min-width: 35%;
    }

    .menu-option p {
        font-size: 15px;
    }

    .menu-heading {
        padding-left: 17.5%;
        margin: 5% 0;
    }

    .menu-actions {
        min-width: 30%;
        font-size: 17px;
        margin-left: 2.5%;
    }

    .menu-actions p {
        font-size: 14px;
    }

    #uk-flag {
        max-width: 24px;
    }

    #french-flag {
        max-width: 22px;
    }
}


