.basket-item {
    color: white;
    background-color: #1F2233;
    max-width: 95%;
    margin: 2% auto 10% auto;
    border-radius: 5px;
}

.basket-item-match {
    display: flex;
    justify-content: space-between;
    padding: 2% 2% 0px 2%;
    font-weight: 400;
    font-size: 13px;
}

.basket-item-bin {
    max-width: 17.5px;
    max-height: 18.5px;
    margin-right: 2.5%;
    cursor: pointer;
}

.basket-item-choice {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 600;
    padding: 2% 2% 0px 2%;
}

.basket-item-paragraph-container {
    font-size: 12px;
    padding: 0px 2%;
}

.basket-item-paragraph-container p {
    font-weight: normal;
    margin-top: 1%;
}

.basket-item-blue {
    color: #16A9CB;
    display: flex;
    align-items: center;
    width: 45%;
    justify-content: end;
    margin-right: 2.5%;
}

.basket-item-team {
    color: #16A9CB;
}

.basket-item-amount-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2.5%;
    font-weight: 600;
    font-size: 13px;
}

.basket-item-amount-container div {
    display: flex;
    max-width: 40%;
}

.basket-item-amount-container input {
    display: flex;
    align-items:center;
    justify-content: end;
}

.basket-item-amount {
    margin-left: 20%;
    background-color: #35435E;
    min-width: 70%;
    max-width: 70%;
    border-radius: 5px;
    color: white;
    border: none;
    text-align: right;
}

.basket-item-amount:focus {
    border: 1px solid rgba(255, 255, 255, 0.4) !important;
}

.basket-item-amount-container div img, .basket-item-amount-container span img {
    max-width: 15px;
    max-height: 15px;
    padding-left: 2.5%;
}

.betWeekTitle {
    color: white;
    text-align: center;
}
 
#basket-link {
    width: 40px;
    position: fixed;
    bottom: 50%;
    right: 5px;
    cursor: pointer;
}

.basket-cross {
    display: none;
}

@media only screen and (min-width: 600px) {
    .basket-item-blue, .basket-item-amount-container input, .basket-item-match {
        font-size: 15px;
    }

    .basket-item-amount-container div img, .basket-item-amount-container span img {
        min-width: 20px;
        min-height: 20px;
    } 

    .basket-item-amount {
        margin-left: 10%;
        min-width: 50%;
        max-width: 50%;
    }

    .basket-item-paragraph-container, .coin-balance {
        font-size: 13px;
    }

    .basket-item-choice {
        font-size: 22px;
    }

    .basket-item-bin {
        min-width: 19px;
        min-height: 20px;
        margin-right: 1.5%;
    }

    .coin-logo {
        padding-right: 5%;
        padding-left: 5%;
    }

    #basket-link {
        width: 45px;
    }
}

@media only screen and (min-width: 750px) {
    #basket-link {
        display: none !important;
    }

    .basket-item {
        background-color: #3A3D45;
    }

    .basket-item-blue, .basket-item-match, .basket-item-paragraph-container p {
        font-size: 9px;
    }

    .basket-item-match {
        padding: 4%;
    }

    .basket-cross {
        display: initial;
        max-width: 15px;
        max-height: 15px;
        padding-right: 2.5%;
    }

    .basket-item-team {
        font-size: 13px;
        color: white;
    }

    .basket-item-odds {
        font-size: 13px;
    }

    .basket-item-amount-container div img {
        align-self: center;
        min-width: 15px;
        min-height: 15px;
        background-color: white;
        margin-right: 2%;
    }

    .basket-item-amount-container span img {
        min-width: 10px;
        min-height: 10px;
        padding-left: 5%;
    }

    .basket-item-blue {
        max-width: 25%;
        color: white;
    }

    .basket-item-amount-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 2.5%;
        font-weight: 600;
        font-size: 13px;
    }

    .basket-item-amount-container div {
        max-width: 61%;
        background-color: white;
        margin-left: 2%;
        border-radius: 5px;
        justify-content: space-between;
    }

    .basket-item-amount-container input {
        background-color: white;
        color: black;
        font-weight: bolder;
        margin-left: 0;
        min-width: 70%;
        font-size: 14px;
    }

    .basket-item-amount-container span img {
        max-width: 10px;
        align-self: end;
    }
}

@media only screen and (min-width: 1100px) {
    .basket-item-blue, .basket-item-match, .basket-item-paragraph-container p {
        font-size: 11px;
    }

    .basket-item-team {
        font-size: 15px;
    }

    .basket-item-odds {
        font-size: 15px;
    }

    .basket-item-blue {
        max-width: 20%;
    }

    .basket-item-amount-container {
        font-size: 13px;
    }

    .basket-item-amount-container input {
        font-size: 16px;
    }
}



@media only screen and (min-width: 1300px) {
    .basket-item-blue, .basket-item-match, .basket-item-paragraph-container p {
        font-size: 12px;
    }

    .basket-item-team {
        font-size: 16px;
    }

    .basket-item-odds {
        font-size: 16px;
    }

    .basket-item-blue {
        max-width: 19%;
    }

    .basket-item-amount-container {
        font-size: 14px;
    }

    .basket-item-amount-container input {
        font-size: 17px;
    }

    .basket-cross {
        max-width: 17.5px;
        max-height: 17.5px;
    }

    .basket-item {
        margin: 2% auto 5% auto;
    }

    .basket-item-amount-container span img {
        min-width: 13px;
        min-height: 13px;
    }

    #basket-review div span img {
        max-width: 13px;
    }
}

@media only screen and (min-width: 1400px) {
    .basket-item-blue, .basket-item-match, .basket-item-paragraph-container p {
        font-size: 14.5px;
    }

    .basket-item-team {
        font-size: 17.5px;
    }

    .basket-item-odds {
        font-size: 17.5px;
    }

    .basket-item-amount-container {
        font-size: 15.5px;
    }

    .basket-item-amount-container input {
        font-size: 18.5px;
    }

    .basket-cross {
        max-width: 19px;
        max-height: 19px;
    }

}

@media only screen and (min-width: 1500px) {
    .basket-item-blue, .basket-item-match, .basket-item-paragraph-container p {
        font-size: 15.5px;
    }

    .basket-item-team {
        font-size: 18.5px;
    }

    .basket-item-odds {
        font-size: 18.5px;
    }
    
    .basket-item-amount-container {
        font-size: 16.5px;
    }

    .basket-item-amount-container input {
        font-size: 19.5px;
    }
}

@media only screen and (min-width: 1600px) {
    .basket-item-blue, .basket-item-match, .basket-item-paragraph-container p {
        font-size: 16px;
    }

    .basket-item-team {
        font-size: 19px;
    }

    .basket-item-odds {
        font-size: 19px;
    }
    
    .basket-item-amount-container {
        font-size: 17px;
    }

    .basket-item-amount-container input {
        font-size: 20px;
    }
}

@media only screen and (min-width: 1700px) {
    .basket-item-blue, .basket-item-match, .basket-item-paragraph-container p {
        font-size: 17px;
    }

    .basket-item-team {
        font-size: 20px;
    }

    .basket-item-odds {
        font-size: 20px;
    }
    
    .basket-item-amount-container {
        font-size: 18px;
    }

    .basket-item-amount-container input {
        font-size: 21px;
    }
}

@media only screen and (min-width: 1800px) {
    .basket-item-blue, .basket-item-match, .basket-item-paragraph-container p {
        font-size: 19px;
    }

    .basket-item-team {
        font-size: 22px;
    }

    .basket-item-odds {
        font-size: 22px;
    }
    
    .basket-item-amount-container {
        font-size: 20px;
    }

    .basket-item-amount-container input {
        font-size: 23px;
    }
}

@media only screen and (min-width: 1900px) {
    .basket-item-blue, .basket-item-match, .basket-item-paragraph-container p {
        font-size: 21px;
    }

    .basket-item-team {
        font-size: 24px;
    }

    .basket-item-odds {
        font-size: 24px;
    }
    
    .basket-item-amount-container {
        font-size: 22px;
    }

    .basket-item-amount-container input {
        font-size: 25px;
    }

    .basket-item-amount-container span img {
        min-width: 19px;
        min-height: 19px;
    }

    #basket-review div span img {
        max-width: 19px;
    }
}