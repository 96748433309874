#bets-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-weight: 500;
    border-bottom: 1.5px solid #FA8C14;
}

#bets-banner span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    width: 70%;
    padding: 2%;
}

#bets-banner-cart {
    max-width: 35px;
    margin-right: 5%;
}