#betting_history {
    color: white;
    margin-top: 15%;
    width: 100%;
    margin-bottom: 15%;
}

#betting_history table {
    border-spacing: 0px;
    width: 95%;
    margin: 0 auto;
    font-size: 11px;
}

#betting_history div h2 {
    text-align: center;
    font-size: 13px;
    text-decoration: underline;
}

#betting_history table thead tr {
    color: aqua;
}

#betting_history table tbody:before {
    line-height:1em; 
    content:"\200C"; 
    display:block;
}

#betting_history table tbody tr {
    background-color: #2a2d3d;
    text-align: center;
}   

#betting_history table tbody tr:nth-child(even) {
    background-color: #242637;
}

.bet-returns {
    font-weight: 700;
}

@media only screen and (min-width: 750px) {
    #betting_history {
        margin-top: 5%;
        margin-bottom: 5%;
    }

    #betting_history table {
        border-spacing: 0px;
        width: 80%;
        margin: 0 auto;
    }

    #betting_history table {
        font-size: initial;
    }
    
    #betting_history div h2 {
        font-size: initial;
    }
}