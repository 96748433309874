#desktop-match-banner {
    display: none;
    width: 100%;
}

#secondary-navbar {
    display: flex;
    min-width: 100%;
    justify-content: space-between;
    background-color: #1F2233;
}

#secondary-navbar-image-container {
    display: flex;
    min-width: 80%;
    justify-content: center;
}

.secondary-navbar-menu-icon-container {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    width: 10%;
}

.secondary-navbar-menu-icon-container.left {
    justify-content: end;
}

.secondary-navbar-menu-icon-container.left img {
    max-width: 13.5px;
    cursor: pointer;
}

.secondary-navbar-menu-icon-container.right {
    justify-content: center;
}

.secondary-navbar-menu-span {
    color: orange;
    font-size: 11px;
    margin-left: 7%;
    padding-top: 2%;
    cursor: pointer;
}

.secondary-navbar-menu-icon-container img {
    max-width: 20px;
    cursor: pointer;
}

.secondary-navbar-image {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin: 0 1% 0% 1%;
    padding: 0 1%;
}

.secondary-navbar-image img {
    max-width: 45px;
    cursor: pointer;
    padding: 7.5px;
}

#desktop-banner-image-container {
    background-image: url('../../images/banner.webp');
    min-width: 100%;
    min-height: 120px;
    background-size: 100% 120px;
    cursor: pointer;
}

@media only screen and (min-width: 750px) {
    #desktop-match-banner {
        display: inline-block;
    }

    .secondary-navbar-selected {
        background-image: linear-gradient(#18243E, #2693AA);
    }
}

@media only screen and (min-width: 1100px) {
    .secondary-navbar-menu-icon-container img {
        max-width: 25px;
    }

    .secondary-navbar-menu-icon-container.left img {
        max-width: 16.5px;
    }

    .secondary-navbar-menu-span {
        font-size: 13px;
    }

    .secondary-navbar-menu-icon-container img {
        max-width: 35px;
    }

    .secondary-navbar-menu-icon-container.left img {
        max-width: 21px;
    }

    .secondary-navbar-menu-span {
        font-size: 15px;
    }

    #desktop-banner-image-container {
        min-height: 140px;
        background-size: 100% 140px;
    }
}

@media only screen and (min-width: 1500px) {
    .secondary-navbar-menu-span {
        font-size: 17.5px;
    }

    #desktop-banner-image-container {
        min-height: 150px;
        background-size: 100% 150px;
    }
}

@media only screen and (min-width: 1600px) {
    .secondary-navbar-image img {
        width: 55px;
    }

    #desktop-banner-image-container {
        min-height: 165px;
        background-size: 100% 165px;
    }
}

@media only screen and (min-width: 1700px) {
    .secondary-navbar-menu-icon-container.left img {
        max-width: 23px;
    }

    .secondary-navbar-menu-span {
        font-size: 19px;
    }

    .secondary-navbar-menu-icon-container img {
        max-width: 37.5px;
    }

    #desktop-banner-image-container {
        min-height: 180px;
        background-size: 100% 180px;
    }
}

@media only screen and (min-width: 1900px) {
    .secondary-navbar-menu-icon-container img {
        max-width: 40px;
    }

    .secondary-navbar-menu-icon-container.left img {
        max-width: 25px;
    }

    .secondary-navbar-menu-span {
        font-size: 23px;
    }

    .secondary-navbar-image img {
        width: 62.5px;
    }
    
    #desktop-banner-image-container {
        min-height: 200px;
        background-size: 100% 200px;
    }
}