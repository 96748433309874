.bet-item {
    color: white;
    background-color: #1F2233;
    max-width: 95%;
    margin: 2% auto 10% auto;
    border-radius: 5px;
}

.bet-item-match {
    display: flex;
    justify-content: space-between;
    padding: 2% 2% 0px 2%;
    font-weight: 400;
}

.bet-item-bin {
    max-width: 17.5px;
    max-height: 25px;
    margin-right: 2.5%;
}

.bet-item-match span {
    font-size: 13px;
}

.bet-item-choice {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 600;
    padding: 2% 2% 0px 2%;
}

.bet-item-paragraph-container {
    font-size: 12px;
    padding: 0px 2%;
}

.bet-item-paragraph-container p {
    font-weight: normal;
    margin-top: 1%;
}

.bet-item-blue {
    color: #16A9CB;
    display: flex;
    align-items: center;
    width: 45%;
    justify-content: end;
    margin-right: 2.5%;
}

.bet-item-team {
    color: #16A9CB;
}

.bet-item-amount-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2.5%;
    font-weight: 600;
    font-size: 13px;
}

.bet-item-amount-container div {
    display: flex;
    width: 45%;
}

.bet-item-amount-container input {
    display: flex;
    align-items:center;
    justify-content: end;
}

.bet-item-amount {
    margin-left: 10%;
    background-color: #35435E;
    min-width: 70%;
    max-width: 70%;
    border-radius: 5px;
    color: white;
    border: none;
    text-align: right;
    padding-right: 2.5%;
}

.bet-item-amount:focus {
    border: 1px solid rgba(255, 255, 255, 0.4) !important;
}

.bet-item-amount-container div img, .bet-item-amount-container span img {
    max-width: 15px;
    max-height: 15px;
    padding-left: 5%;
    align-self: center;
}

.betWeekTitle {
    color: white;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
}
 
@media only screen and (min-width: 600px) {
    .bet-item-blue, .bet-item-amount-container input {
        font-size: 15px;
    }

    .bet-item-match span {
        font-size: 15px;
    }

    .bet-item-amount-container div img, .bet-item-amount-container span img {
        min-width: 20px;
        min-height: 20px;
    } 

    .bet-item-amount {
        margin-left: 10%;
        min-width: 50%;
        max-width: 50%;
    }

    .bet-item-paragraph-container, .coin-balance {
        font-size: 13px;
    }

    .bet-item-choice {
        font-size: 22px;
    }

    .betWeekTitle {
        font-size: 16.5px;
    }

    .bet-item-bin {
        min-width: 19px;
        min-height: 20px;
        margin-right: 1.5%;
    }

    .coin-logo {
        padding-right: 5%;
        padding-left: 5%;
    }
}

@media only screen and (min-width: 750px) {
    .betWeekTitle {
        margin: 1%;
    }
    
    .bet-item-match span, .bet-item-paragraph-container {
        font-size: 11px;
    }

    #bets-banner span {
        padding: 1.5% 4%;
    }

    .bet-item-match {
        padding: 1% 1% 0 1%;
    }

    .bet-item-amount-container {
        padding: 1%;
    }

    .bet-item-paragraph-container p {
        padding: 1% 1% 0 2%;
        margin: 0;
    }

    .bet-item-choice {
        padding: 1% 3% 0 3%;
        font-size: 14px;
    }

    .bet-item {
        margin: 2% auto 3% auto;
        width: 55%;
    }

    .bet-item-amount {
        margin-left: 2%;
        max-width: 40%;
        min-width: 40%;
        font-size: 12px;
    }

    .bet-item-amount-container div img, .bet-item-amount-container span img {
        min-width: 12.5px;
        min-height: 12.5px;
        max-width: 12.5px;
        max-height: 12.5px;
        padding-left: 1%;
    }

    .bet-item-blue {
        font-size: 12px;
    }
}

@media only screen and (min-width: 750px) {
    .betWeekTitle {
        margin: 2%;
        font-size: 22px;
    }
    
    .bet-item-match span, .bet-item-paragraph-container {
        font-size: 17px;
    }

    #bets-banner span {
        padding: 1.8% 3%;
        font-size: 21px;
    }

    .bet-item-match {
        padding: 1.5% 1.5% 0 1.5%;
    }

    .bet-item-amount-container {
        padding: 1.5%;
    }

    .bet-item-paragraph-container p {
        padding: 1.5% 1.5% 0 2.5%;
    }

    .bet-item-choice {
        padding: 1.5% 3.5% 0 3.5%;
        font-size: 20px;
    }

    .bet-item {
        margin: 2.5% auto 3% auto;
        width: 65%;
    }

    .bet-item-amount {
        margin-left: 2.5%;
        max-width: 40%;
        min-width: 40%;
        font-size: 18px;
    }

    .bet-item-amount-container div img, .bet-item-amount-container span img {
        min-width: 18px;
        min-height: 18px;
        max-width: 18px;
        max-height: 18px;
        padding-left: 1.5%;
    }

    .bet-item-blue {
        font-size: 18px;
    }
}

@media only screen and (min-width: 1600px) {
    .betWeekTitle {
        font-size: 24px;
    }
    
    .bet-item-match span, .bet-item-paragraph-container {
        font-size: 19px;
    }

    #bets-banner span {
        font-size: 22px;
    }

    .bet-item-choice {
        font-size: 22px;
    }

    .bet-item-amount {
        font-size: 20px;
    }

    .bet-item-blue {
        font-size: 20px;
    }
}