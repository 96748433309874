#leaderboard_banner {
    display: flex;
    color: white;
    align-content: end;
    border-bottom: 2px solid orange;
}

#leaderboard_podium {
    max-width: 40px;
    max-height: 30px;
    align-self: center;
    margin-left: 1.5%;
}

#leaderboard_banner_heading {
    width: fit-content;
    font-weight: 500;
    font-size: 1.2rem;
}

#leaderboard_headings {
    display: grid;
    grid-template-columns: 0.5fr repeat(3, 1fr);
    margin: 2% auto;
    width: 97%;
}

#position_number_heading {
    justify-self: center;
    color: aqua;
    font-weight: bold;
}

#leaderboard_headings span {
    color: aqua;
    font-weight: bold;
    font-size: 1.1rem;
}

#user_leaderboard_field {
    max-width: 97%;
    background-color: #2A2D3D;
    margin: 0 auto;
    border-radius: 5px;
    margin-bottom: 2%;
}

#leaderboard_table {
    max-width: 97%;
    background-color: #2A2D3D;
    margin: 0 auto;
    border-radius: 5px;
}

.user_leaderboard_data {
    display: grid;
    grid-template-columns: 0.5fr repeat(3, 1fr);
    padding: 15px 0;
    font-weight: bold;
    color: white;
    font-size: 0.8rem;
}

.users_leaderboard_data {
    display: grid;
    grid-template-columns: 0.5fr repeat(3, 1fr);
    padding: 15px 0;
    font-weight: bold;
    color: white;
    font-size: 0.8rem;
    align-items: center
}

.users_leaderboard_data:nth-child(even) {
    background-color: #242637;
}

.leaderboard_position {
    justify-self: center;
    font-size: 0.6rem;
}

#win_rate_heading {
    justify-self: center;
}

.leaderboard_percentage {
    justify-self: center;
}

.leaderboard_balance {
    display: flex;
    width: 80%;
    justify-content: end;
}

.points_gold_coin {
    max-width: 15px;
    max-height: 12.5px;
    align-self: center;
    margin-left: 2.5%;
}

#first_place, #second_place, #third_place {
    max-width: 27.5px;
}

#total_points_heading {
    justify-self: center;
}

#leaderboard_prizes_container {
    display: none;
}

@media only screen and (min-width: 600px) {
    #leaderboard_podium {
        max-width: 45px;
        max-height: 40px;
        margin-left: 5%;
    }

    #leaderboard_banner_heading {
        margin-left: 2%;
    }

    .user_leaderboard_data {
        font-size: 0.95rem;
    }

    .users_leaderboard_data {
        font-size: 0.95rem;
    }

    .leaderboard_position {
        align-self: center;
        font-size: 0.7rem;
    }

    #first_place, #second_place, #third_place {
        max-width: 30px;
    }

    .points_gold_coin {
        max-width: 17.5px;
        max-height: 15px;
    }

    #total_points_heading {
        justify-self: end;
        padding-right: 22.5%;
    }
}

@media only screen and (min-width: 750px) {
    .user_leaderboard_data, .users_leaderboard_data {
        padding: 10px;
        font-size: 0.8rem;
    }

    #leaderboard_banner_heading {
        margin: 2%;
    }

    #leaderboard_headings span {
        font-size: 1rem;
    }

    #leaderboard_table {
        margin: 2% 1%;
        max-width: 100%;
    }
    
    #user_leaderboard_field {
        margin: 2% 1%;
        max-width: 100%;
    }

    #leaderboard_headings {
        margin: 2%;
        max-width: 100%;
    }

    #leaderboard-section-container {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr;
    }

    #leaderboard-container {
        grid-column: 1/2;
        grid-row: 1/2;
    }

    #leaderboard_prizes_container {
        grid-column: 2/3;
        grid-row: 1/2;
        width: 90%;
        justify-self: center;
        margin-top: 5%;
        display: initial;
    }

    #leaderboard_prizes_container img {
        max-width: 100%;
    }

    #leaderboard {
        position: relative;
        top: 61px;
    }
}

@media only screen and (min-width: 1100px) {
    #leaderboard_podium {
        max-width: 57.5px;
        max-height: 53px;
        margin-left: 5.7%;
    }

    #first_place, #second_place, #third_place {
        max-width: 35px;
    }

    .leaderboard_position {
        font-size: 0.75rem;
    }
}

@media only screen and (min-width: 1300px) {
    .user_leaderboard_data, .users_leaderboard_data {
        font-size: 1.1rem;
        padding: 12.5px;
    }

    .leaderboard_position {
        font-size: 0.9rem;
    }

    #first_place, #second_place, #third_place {
        max-width: 40px;
    }

    #leaderboard_headings span {
        font-size: 1.2rem;
    }

    #leaderboard_banner_heading {
        font-size: 1.3rem;
    }

    #leaderboard_podium {
        max-width: 65px;
        max-height: 55px;
        height: 55px;
    }
}

@media only screen and (min-width: 1400px) {
    .user_leaderboard_data, .users_leaderboard_data {
        font-size: 1.2rem;
        padding: 14px;
    }

    .leaderboard_position {
        font-size: 1rem;
    }

    #leaderboard_headings span {
        font-size: 1.3rem;
    }

    #leaderboard_banner_heading {
        font-size: 1.4rem;
    }
}

@media only screen and (min-width: 1800px) {
    .user_leaderboard_data, .users_leaderboard_data {
        font-size: 1.4rem;
    }

    .leaderboard_position {
        font-size: 1.15rem;
    }

    #first_place, #second_place, #third_place {
        max-width: 50px;
    }
}