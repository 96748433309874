#keyboard {
    display: none;
    grid-template: repeat(4, 1fr) / repeat(4, 1fr);
    grid-gap: 0px;
    color: white;
    justify-items: center;
    margin: 0% auto;
    background-color: #1F2233;
    border-top: 3px solid #268CA4;
    position: sticky;
    bottom: 0px;
    width: 100%;
}

#keyboard, #desktopKeyboard {
    margin-top: 10%;
}

.keyboard-hidden {
    display: none;
}

.keyboard-key-non-fixed {
    margin: 0px;
    width: 95%;
    height: 95%;
    padding: 10px 0%;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    font-weight: bold;
}

.keyboard-coin {
    max-width: 20px;
    margin-left: 5%;
}

.fixed-button {
    background-color: #35435E;
    min-height: 75%;
    max-height: 75%;
    border-radius: 5px;
    min-width: 90%;
    margin: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    font-size: 13px;
    align-self: center;
}

.keyboard-navy {
    background-color: #35435E;
    font-weight: 500;
}

.keyboard-blue {
    background-color: #16A9CB;
}

.keyboard-delete-button {
    max-width: 40px;
}

#desktopKeyboard {
    display: none;
    grid-template: repeat(4, 1fr) / repeat(4, 1fr);
    grid-gap: 0px;
    color: white;
    justify-items: center;
    margin: 0 auto;
    background-color: #1F2233;
    border-top: 3px solid #268CA4;
    position: sticky;
    bottom: 0px;
}

@media only screen and (min-width: 750px) {
    #desktopKeyboard {
        z-index: 1;
        border-bottom: 2px solid #268CA4;
    }
}