.live-match-odds-container {
    display: inline-flex;
    width: 100%;
}

.live-match-odds-container div span {
    margin: 0px 5%;
    font-size: 12px;
}

.match-left-team, .match-right-team {
    width: 100%;
    display: inline-block;
    background-color: #35435E;
    margin: 1%;
    border-radius: 5px;
    padding: 3% 0%;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 600;
}

.versus-text {
    display: none;
    font-size: 9px;
    color: gray;
    font-style: italic;
    font-weight: 500;
} 

@media only screen and (min-width: 600px) {
    .live-match-odds-container div span {
        font-size: 16.5px;
    }
}

@media only screen and (min-width: 750px) {
    .live-match-odds-container div span {
        font-size: 9px;
    }

    .match-left-team {
        display: grid;
        grid-template-columns: 1.5fr 0.5fr;
        grid-template-rows: repeat(1, 1fr);
        align-items: center;
        border-radius: 0;
        position: relative;
    }

    .match-right-team {
        display: grid;
        grid-template-columns: 0.5fr 1.5fr;
        grid-template-rows: repeat(1, 1fr);
        align-items: center;
        border-radius: 0;
        position: relative;
    }

    .match-left-team {
        grid-column: 1/3;
    }

    .match-right-team {
        grid-column: 4/7;
    }

    .left-team-match-odds, .right-team-match-name {
        grid-column: 2/3;
        grid-row: 1/2;
    }

    .left-team-match-odds {
        justify-self: end;
        text-align: right;
    }

    .live-match-odds-container div span.left-team-match-odds, .live-match-odds-container div span.right-team-match-odds {
        font-size: 12px;
    }

    .right-team-match-name {
        justify-self: end;
        padding-right: 2%;
    } 

    .live-match-odds-container div span.right-team-match-name {
        font-size: 9px;
    }

    .left-team-match-name {
        padding-left: 2%;
    }

    .versus-text {
        display: initial;
        grid-column: 3/4;
        justify-self: center;
        align-self: center;
        border-bottom-left-radius: 25px;
        border-top-left-radius: 25px;
    }

    .match-left-team::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -14.5px; /* Adjust this value to control the angle */
        margin: auto 0;
        width: 0;
        height: 0;
        border-top: 15px solid transparent; /* Adjust this value to control the size of the arrow */
        border-bottom: 15px solid transparent; /* Adjust this value to control the size of the arrow */
        border-left: 15px solid #35435E;
    }

    .match-right-team::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -14.5px; /* Adjust this value to control the angle */
        margin: auto 0;
        width: 0;
        height: 0;
        border-top: 15px solid transparent; /* Adjust this value to control the size of the arrow */
        border-bottom: 15px solid transparent; /* Adjust this value to control the size of the arrow */
        border-right: 15px solid #35435E;
    }

    .live-match-odds-container div span.right-team-match-odds, .live-match-odds-container div span.right-team-match-name, .live-match-odds-container div span.left-team-match-odds, .live-match-odds-container div span.left-team-match-name {
        font-size: 11px;
    }
}

@media only screen and (min-width: 900px) {
    .match-left-team::after {
        right: -14.38px; /* Adjust this value to control the angle */
    }

    .match-right-team::before {
        left: -14.4px; /* Adjust this value to control the angle */
    }
}

@media only screen and (min-width: 1100px) {
    .versus-text span {
        font-weight: bold;
        font-size: 11px;
    }

    .live-match-odds-container div span.right-team-match-odds, .live-match-odds-container div span.right-team-match-name, .live-match-odds-container div span.left-team-match-odds, .live-match-odds-container div span.left-team-match-name {
        font-size: 11px;
    }
}

@media only screen and (min-width: 1300px) {
    .versus-text span {
        font-weight: bold;
        font-size: 12px;
    }

    .live-match-odds-container div span.right-team-match-odds, .live-match-odds-container div span.right-team-match-name, .live-match-odds-container div span.left-team-match-odds, .live-match-odds-container div span.left-team-match-name {
        font-size: 11px;
    }

    .match-left-team::after {
        right: -14.33px; /* Adjust this value to control the angle */
    }

    .match-right-team::before {
        left: -14.3px; /* Adjust this value to control the angle */
    }
    
}

@media only screen and (min-width: 1500px) {
    .match-left-team::after {
        right: -14.29px; /* Adjust this value to control the angle */
    }

    .match-right-team::before {
        left: -14px; /* Adjust this value to control the angle */
    }
}

@media only screen and (min-width: 1600px) {
    .live-match-odds-container div span.right-team-match-odds, .live-match-odds-container div span.right-team-match-name, .live-match-odds-container div span.left-team-match-odds, .live-match-odds-container div span.left-team-match-name {
        font-size: 17.5px;
    }    

    .versus-text span {
        font-weight: bold;
        font-size: 13px;
    }
}

@media only screen and (min-width: 1700px) {
    .live-match-odds-container div span.right-team-match-odds, .live-match-odds-container div span.right-team-match-name, .live-match-odds-container div span.left-team-match-odds, .live-match-odds-container div span.left-team-match-name {
        font-size: 11px;
    }    

    .versus-text span {
        font-weight: bold;
        font-size: 13.5px;
    }
}

@media only screen and (min-width: 1900px) {
    .live-match-odds-container div span.right-team-match-odds, .live-match-odds-container div span.right-team-match-name, .live-match-odds-container div span.left-team-match-odds, .live-match-odds-container div span.left-team-match-name {
        font-size: 12px;
    }    

    .versus-text span {
        font-weight: bold;
        font-size: 14px;
    }
} 