.match_setting_container {
    background-color: #1F2233;
}

.settings_form {
    display: grid;
    grid-template-columns: repeat(10, 10%);
    grid-template-rows: repeat(9, 0.5fr);
    background-color: #1F2233;
    border: 0.5px solid #572092;
    border-radius: 5px;
    width: 99%;
    margin: 0 auto;
}

.match_settings_category_select {
    background-color: #35435E;
    color: #3C9FAA;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    margin-top: 4%;
    margin-left: 4%;
    grid-column: 1/5;
    font-size: 12px;
}

.settings-cog-image {
    grid-column: 10/11;
    grid-row: 1/2;
    justify-self: center;
    align-self: center;
    max-width: 15px;
    cursor: pointer;
}

.match_settings_span {
    grid-column: 1/5;
    grid-row: 2/3;
    color: white;
    font-size: 10px;
    margin-left: 4%;
    margin-top: 2%;
}

.match_date_setting_input {
    grid-column: 6/11;
    grid-row: 2/3;
    background-color: #35435E;
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 4%;
    margin-right: 4%;
    text-align: center;
    max-width: 50%;
    justify-self: end;
    font-size: 12px;
}

.match_time_setting_input {
    grid-column: 6/11;
    grid-row: 3/4;
    background-color: #35435E;
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 4%;
    margin-right: 4%;
    text-align: center;
    max-width: 50%;
    justify-self: end;
    font-size: 12px;
}

.team_one_name_odds {
    grid-column: 4/6;
    grid-row: 5/6;
    justify-self: center;
    text-align: right;
    font-weight: 500;
    font-size: 12px;
    align-self: end;
    color: white;
    margin-right: 2%;
}

.settings_team_one_name {
    grid-column: 1/4;
    grid-row: 5/6;
    background-color: #35435E;
    border-radius: 5px;
    min-width: 100%;
    max-width: 100%;
    text-align: left;
    padding-left: 1%;
    margin-top: 4%;
    color: white;
    padding: 0 1% 0% 2%;
    font-size: 12px;
    margin-left: 3%;
    border: none;
}

.team_two_name_odds {
    grid-column: 6/8;
    grid-row: 5/6;
    justify-self: center;
    font-weight: 500;
    font-size: 12px;
    color: white;
    align-self: end;
    margin-left: 2%;
}

.settings_team_two_name {
    grid-column: 8/11;
    grid-row: 5/6;
    background-color: #35435E;
    border-radius: 5px;
    min-width: 100%;
    max-width: 100%;
    margin-top: 4%;
    color: white;
    padding: 0 2% 1% 0%;
    text-align: right;
    justify-self: end;
    margin-right: 3%;
    font-size: 12px;
    border: none;
}

.team_one_name {
    grid-column: 1/2;
    grid-row: 6/7;
    max-width: 15px;
    justify-self: end;
    align-self: center;
}

input[type="radio"] {
    accent-color: #8E2AEE;
    cursor: pointer;
}

.winner_radio_label_team_one {
    grid-column: 2/5;
    grid-row: 6/7;
    padding-left: 2.5%;
    color: white;
    margin-left: 4%;
    margin-top: 4%;
    font-size: 12px;
}

.team_two_name {
    grid-column: 8/9;
    grid-row: 6/7;
    max-width: 15px;
    justify-self: start;
}

.winner_radio_label_team_two {
    grid-column: 9/11;
    grid-row: 6/7;
    padding-left: 2.5%;
    color: white;
    margin-right: 4%;
    margin-top: 4%;
    text-align: left;
    font-size: 12px;
}

.stream_link_input {
    grid-column: 1/11;
    grid-row: 7/8;
    background-color: #35435E;
    border: none;
    border-radius: 5px;
    margin: 3% auto;
    margin-top: 3%;
    width: 98%;
    padding: 1% 0 1% 1%;
    font-size: 12px;
    color: white;
}

.modify_bet_setting_button {
    grid-column: 1/11;
    grid-row: 8/9;
    background-color: #8E2AEE;
    color: white;
    font-weight: 500;
    border: none;
    width: 98%;
    border-radius: 5px;
    margin: 0 auto 1% auto;
    padding: 1% 0;
}

.cancel_bet_setting_button {
    grid-column: 1/6;
    grid-row: 9/10;
    background-color: #7D1200;
    color: white;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    width: 96%;
    padding: 1% 0;
    margin: 0 auto 2% auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.validate_match_setting_button {
    grid-column: 6/11;
    grid-row: 9/10;
    background-color: #119200;
    color: white;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    width: 96%;
    padding: 1% 0;
    margin: 0 auto 2% auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form_feedback_error {
    color: red;
}

.cancel_bet_bin_image {
    max-width: 17px;
    vertical-align: middle;
    margin-right: 2%;
}

.complete_bet_tick_image {
    max-width: 12px;
    vertical-align: middle;
    margin-right: 4%;
}

.cancel_bet_setting_button, .modify_bet_setting_button, .validate_match_setting_button {
    cursor: pointer;
}

.match_confirmed_label {
    color: white;
    font-size: 12px;
    grid-column: 1/6;
    grid-row: 4/5;
    margin-left: 4%;
    margin-top: 4% !important;
}

.match_confirmed {
    grid-column: 10/11;
    grid-row: 4/5;
    align-self: end;
    height: 50%;
}

@media only screen and (min-width: 600px) {
    .match_date_setting_input, .match_time_setting_input, .settings_team_two_name, .settings_team_one_name, .winner_radio_label_team_two, .winner_radio_label_team_one {
        font-size: 14px;
    }

    .match_settings_span {
        font-size: 12px;
        margin-left: 5.5%;
    }

    .settings-cog-image {
        max-width: 17.5px;
    }

    .team_one_name_odds, .team_two_name_odds {
        font-size: 16px
    }

    .stream_link_input {
        font-size: 14px;
        margin: 2% auto;
    }
}

@media only screen and (min-width: 750px) {
    .match_settings_category_select, .match_date_setting_input, .match_time_setting_input, .settings_team_two_name, .settings_team_one_name, .winner_radio_label_team_two, .winner_radio_label_team_one, .match_confirmed_label {
        font-size: 12px;
    }

    .match_settings_span {
        font-size: 10px;
        margin-left: 5.5%;
        height: fit-content;
    }

    .settings_form {
        max-width: 55%;
        margin-bottom: 2%;
    }

    .settings_team_one_name, .settings_team_two_name {
        align-self: center;
        padding: 1%;
    }

    .match_date_setting_input, .match_time_setting_input {
        margin-top: 1%;
        height: fit-content;
    }

    .settings-cog-image {
        max-width: 12.5px;
    }

    .team_one_name_odds, .team_two_name_odds {
        font-size: 14px;
        align-self: flex-end;
    }

    .stream_link_input {
        font-size: 12px;
        margin: 1% auto;
        height: fit-content;
        padding: 0.5%;
        width: 97%;
    }

    .modify_bet_setting_button, .validate_match_setting_button, .cancel_bet_setting_button {
        font-size: 14px;
        padding: 0.5%;
    }

    .modify_bet_setting_button {
        margin: 0 auto 0.5% auto;
    }

    .validate_match_setting_button, .cancel_bet_setting_button {
        margin: 0 auto 1% auto;
    }

    #team_two_name {
        justify-self: end;
        margin-right: 20%;
    }

    .cancel_bet_bin_image {
        max-width: 11px;
    }

    .complete_bet_tick_image {
        max-width: 10px;
    }
}

@media only screen and (min-width: 900px) {
    .match_settings_category_select, .match_date_setting_input, .match_time_setting_input, .settings_team_two_name, .settings_team_one_name, .winner_radio_label_team_two, .winner_radio_label_team_one, .match_confirmed_label {
        font-size: 13px;
    }

    .match_settings_span {
        font-size: 11px;
    }

    .team_one_name_odds, .team_two_name_odds {
        font-size: 15px;
    }

    .stream_link_input {
        font-size: 13px;
    }
}

@media only screen and (min-width: 1100px) {
    .match_settings_category_select, .match_date_setting_input, .match_time_setting_input, .settings_team_two_name, .settings_team_one_name, .winner_radio_label_team_two, .winner_radio_label_team_one, .match_confirmed_label {
        font-size: 15px;
    }

    .match_settings_span {
        font-size: 12px;
    }

    .modify_bet_setting_button, .validate_match_setting_button, .cancel_bet_setting_button {
        font-size: 16px;
    }

    .settings-cog-image {
        max-width: 15px;
    }

    .cancel_bet_bin_image {
        max-width: 13px;
    }

    .team_one_name_odds, .team_two_name_odds {
        font-size: 16px;
    }

    .stream_link_input {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1300px) {
    .match_settings_category_select, .match_date_setting_input, .match_time_setting_input, .settings_team_two_name, .settings_team_one_name, .winner_radio_label_team_two, .winner_radio_label_team_one, .match_confirmed_label {
        font-size: 16.5px;
    }

    .match_settings_span {
        font-size: 13.5px;
    }

    .modify_bet_setting_button, .validate_match_setting_button, .cancel_bet_setting_button {
        font-size: 17.5px;
    }

    .settings-cog-image {
        max-width: 16.5px;
    }

    .cancel_bet_bin_image {
        max-width: 14.5px;
    }

    .team_one_name_odds, .team_two_name_odds {
        font-size: 17px;
    }

    .stream_link_input {
        font-size: 15px;
    }
}

@media only screen and (min-width: 1400px) {
    .match_settings_category_select, .match_date_setting_input, .match_time_setting_input, .settings_team_two_name, .settings_team_one_name, .winner_radio_label_team_two, .winner_radio_label_team_one, .match_confirmed_label {
        font-size: 18px;
    }

    .match_settings_span {
        font-size: 15px;
    }

    .modify_bet_setting_button, .validate_match_setting_button, .cancel_bet_setting_button {
        font-size: 19px;
    }

    .settings-cog-image {
        max-width: 18px;
    }

    .cancel_bet_bin_image {
        max-width: 16px;
    }

    .team_one_name_odds, .team_two_name_odds {
        font-size: 18px;
    }

    .stream_link_input {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1600px) {
    .match_settings_category_select, .match_date_setting_input, .match_time_setting_input, .settings_team_two_name, .settings_team_one_name, .winner_radio_label_team_two, .winner_radio_label_team_one, .match_confirmed_label {
        font-size: 20px;
    }

    .match_settings_span {
        font-size: 17px;
    }

    .modify_bet_setting_button, .validate_match_setting_button, .cancel_bet_setting_button {
        font-size: 21px;
    }

    .settings-cog-image {
        max-width: 20px;
    }

    .cancel_bet_bin_image {
        max-width: 20px;
    }

    .complete_bet_tick_image {
        max-width: 15px;
    }

    .team_one_name_odds, .team_two_name_odds {
        font-size: 20px;
    }

    .stream_link_input {
        font-size: 18px;
    }
}

@media only screen and (min-width: 1800px) {
    .match_settings_category_select, .match_date_setting_input, .match_time_setting_input, .settings_team_two_name, .settings_team_one_name, .winner_radio_label_team_two, .winner_radio_label_team_one, .match_confirmed_label {
        font-size: 21.5px;
    }

    .match_settings_span {
        font-size: 18.5px;
    }

    .modify_bet_setting_button, .validate_match_setting_button, .cancel_bet_setting_button {
        font-size: 22.5px;
    }

    .settings-cog-image {
        max-width: 21.5px;
    }

    .cancel_bet_bin_image {
        max-width: 21.5px;
    }

    .complete_bet_tick_image {
        max-width: 16.5px;
    }

    .team_one_name_odds, .team_two_name_odds {
        font-size: 21.5px;
    }

    .stream_link_input {
        font-size: 19.5px;
    }
}