#events-side-menu {
    width: 22%;
    position: fixed;
    left: 0;
    top: 61px;
    background-color: #1F2233;
    min-height: calc(100vh - 51px);
    max-height: calc(100vh - 51px);
    z-index: 98;
}

#events-side-menu div div img {
    max-width: 21px;
    margin-left: 2.5%;
}

#events-side-menu div div span, #events-side-menu div span {
    font-size: 11px;
}

#events-side-menu div div span {
    margin-left: 5%;
}

#events-side-menu div div {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    max-width: 100%;
    padding: 0px;
    margin: 2% 0;
    cursor: pointer;
}

#events-side-menu div div div {
    width: 85%;
}

#events-side-menu div span {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    margin-right: 2.5%;
}

#cwc-events {
    display: flex;
    color: #03EC43;
    justify-content: space-between;
    margin: 0 auto;
    align-content: center;
    flex-wrap: wrap;
    font-weight: bold;
}

#cwc-events:hover {
    background-color: #03EC43;
    color: white;
}

#civ-events {
    color: #E6920F;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-content: center;
    flex-wrap: wrap;
    font-weight: bold;
}

#civ-events:hover {
    background-color: #E6920F;
    color: white;
}

#cpl-events {
    color: #902319;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-content: center;
    flex-wrap: wrap;
    font-weight: bold;
}

#cpl-events:hover {
    background-color: #902319;
    color: white;
}

#others-events {
    color: #0C62D0;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-content: center;
    flex-wrap: wrap;
    font-weight: bold;
}

#others-events:hover {
    background-color: #0C62D0;
    color: white;
}

@media only screen and (min-width: 1100px) {
    #events-side-menu div div span, #events-side-menu div span {
        font-size: 13px;
    }

    #events-side-menu {
        width: 17.5%;
    }
}

@media only screen and (min-width: 1300px) {
    #events-side-menu div div span, #events-side-menu div span {
        font-size: 15px;
    }

    #events-side-menu div div img {
        max-width: 25px;
    }
}

@media only screen and (min-width: 1400px) {
    #events-banner img {
        width: 14px;
    }
}

@media only screen and (min-width: 1600px) {
    #events-side-menu div div span, #events-side-menu div span {
        font-size: 17.5px;
    }

    #events-banner img {
        max-width: 19px;
        width: 19px;
    }
}

@media only screen and (min-width: 1800px) {
    #events-side-menu div div span, #events-side-menu div span {
        font-size: 19px;
    }

    #events-side-menu div div {
        margin: 3% 0;
    }

    #events-banner img {
        max-width: 21px;
        width: 21px;
    }
}

@media only screen and (min-width: 1900px) {
    #events-side-menu div div span, #events-side-menu div span {
        font-size: 23px;
    }

    #events-banner img {
        max-width: 25px;
        width: 25px;
    }
}