.upcoming-match-container {
    color: white;
    margin: 0 auto 5% auto;
    background-color: #1F2233;
    width: 95%;
    border-radius: 5px;
}

#mobileBanner {
    background-image: url('../../images/mobile_banner.webp');
    min-width: 100%;
    min-height: 140px;
    background-size: 100% 100%;
}

.cog-image {
    max-width: 15px;
    float: right;
    margin-right: 2%;
    margin-top: 2%;
    cursor: pointer;
}

#match-betting-category {
    color: aqua;
}

.category-sub-title p {
    text-align: center;
    width: 100%;
    color: rgb(205, 203, 203);
    font-weight: 500;
    display: inline-block;
    font-size: 15px;
}

.upcoming-match-container p {
    width: 100%;
    display: flex;
    color: white;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    margin: 1%;
}

.upcoming-match-date-span {
    font-size: 10.5px;
    display: inline-block;
    padding: 1% 4%;
}

.upcoming-match-date-span {
    float: right;
}

.upcoming-match-division {
    color: aqua;
    font-weight: 500;
    font-size: 13px;
    padding: 3% 0 1.5% 3%;
    display: inline-block;
    min-width: fit-content;
    width: 90%;
    max-width: 90%;
}

.upcoming-match-time-span {
    display: none;
}

.match_stream_arrow {
    display: none;
    max-width: 8px;
    transform: rotate(-90deg);
    cursor: pointer;
}

@media only screen and (min-width: 500px) {
    #mobileBanner {
        background-image: url('../../images/banner.webp');
        min-height: 75px;
    }
}

@media only screen and (min-width: 650px) {
    #mobileBanner {
        min-height: 90px;
    }
}

@media only screen and (min-width: 600px) {
    .category-sub-title p {
        font-size: 16px;
    }

    .upcoming-match-division {
        font-size: 15px;
        padding: 2% 0 2% 2%
    } 

    .upcoming-match-container p span {
        padding: 0 5%;
        font-size: 14px;
    }

    .cog-image {
        max-width: 17.5px;
    }
    

    .match-left-team, .match-right-team {
        padding: 1% 0;
        font-size: 15px;
        cursor: pointer;
    }
}

@media only screen and (min-width: 750px) {
    .upcoming-match-container {
        display: grid !important;
        grid-template-columns: repeat(15, 1fr);
        max-width: 85%;
        border-radius: 0px;
        min-height: 32.5px;
        margin: 0 auto 2.5% auto
    }

    #categoryMatches {
        position: relative;
        top: 61px;
    }

    #mobileBanner {
        display: none;
    }

    .upcoming-match-division {
        font-size: 12px;
        grid-column: 1/6;
        align-self: center;
        font-size: 10px;
        grid-row: 1/2;
    }

    .upcoming-match-date-span {
        grid-column: 12/15;
        justify-self: end;
        font-size: 10px;
        grid-row: 1/2;
        align-self: center;
    }

    .upcoming-match-container p span {
        font-size: 10px;
    }

    .cog-image {
        grid-column: 15/16;
        align-self: center;
        justify-self: start;
        max-width: 12.5px;
        grid-row: 1/2;
        margin-left: 15%;
    }

    .live-match-odds-container {
        grid-column: 6/11;
        grid-row: 1/2;
        display: grid;
        grid-template-columns: 1fr 0.5fr 0.75fr 0.5fr 1fr;
    }

    .category-sub-title p {
        font-size: 14px;
    }
    
    .upcoming-match-time-span {
        display: initial;
    }

    .match_stream_arrow {
        display: initial;
        grid-column: 15/16;
        align-self: center;
        justify-self: end;
        grid-row: 1/2;
        margin-right: 5%;
    }

    .upcoming-match-division p {
        display: none !important;
    }

    .live-stream-container {
        display: none;
    }

    .desktop-live-channel {
        background-image: linear-gradient(to right, #1F2233, rgba(255, 56, 56, 0.573));
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: right;
    }
}

@media only screen and (min-width: 900px) {
    .upcoming-match-container {
        margin: 0 auto 1.5% auto;
    }
}

@media only screen and (min-width: 1100px) {
    .upcoming-match-division {
        font-size: 12px;
    }

    .live-match-odds-container div span {
        font-size: 13px;
    }

    .upcoming-match-date-span, .upcoming-match-time-span {
        font-size: 12px;
    }

    .category-sub-title p {
        font-size: 17px;
    }
}

@media only screen and (min-width: 1300px) {
    .upcoming-match-container {
        max-width: 80%;
    }

    .upcoming-match-division {
        font-size: 14.5px;
    }

    .live-match-odds-container div span {
        font-size: 14.5px;
    }

    .upcoming-match-date-span, .upcoming-match-time-span {
        font-size: 13.5px;
    }

    .category-sub-title p {
        font-size: 18.5px;
    }

    .match_stream_arrow {
        max-width: 11px;
    }

    .cog-image {
        max-width: 13.5px;
        margin-left: 20%;
    }
}

@media only screen and (min-width: 1400px) {
    .upcoming-match-division {
        font-size: 15px;
    }

    .upcoming-match-date-span, .upcoming-match-time-span {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1500px) {
    .upcoming-match-division {
        font-size: 16px;
        padding: 1.5%;
    }

    .upcoming-match-date-span, .upcoming-match-time-span {
        font-size: 15px;
    }

    .cog-image {
        max-width: 15px;
    }

    .match_stream_arrow {
        max-width: 12px;
    }

    .category-sub-title p {
        font-size: 19px;
    }
}

@media only screen and (min-width: 1600px) {

    .upcoming-match-division {
        font-size: 17.5px;
        padding: 2%;
    }

    .upcoming-match-date-span, .upcoming-match-time-span {
        font-size: 16.5px;
    }

    .cog-image {
        max-width: 16px;
    }

    .match_stream_arrow {
        max-width: 13px;
    }

    .category-sub-title p {
        font-size: 20px;
    }
}

@media only screen and (min-width: 1700px) {
    .upcoming-match-division {
        font-size: 19.5px;
        padding: 1.5%;
    }

    .upcoming-match-date-span, .upcoming-match-time-span {
        font-size: 17.5px;
    }

    .category-sub-title p {
        font-size: 21px;
    }
}

@media only screen and (min-width: 1800px) {
    .upcoming-match-division {
        font-size: 20px;
    }

    .upcoming-match-date-span, .upcoming-match-time-span {
        font-size: 18px;
    }

    .category-sub-title p {
        font-size: 21.5px;
    }
}

@media only screen and (min-width: 1900px) {
    .upcoming-match-division {
        font-size: 21.5px;
    }

    .upcoming-match-date-span, .upcoming-match-time-span {
        font-size: 19.5px;
    }

    .category-sub-title p {
        font-size: 23px;
    }

    .category-sub-title p {
        font-size: 25px;
    }
}