

#login-button {
    background-color: #8e2aee;
    color: white;
    max-width:90%;
    height: fit-content;
    padding: 1.5% 3%;
    border-radius: 5px;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    border: none;
    margin-right: 1%;
    cursor: pointer;
}

#login-logo {
    max-width: 1.15rem;
    margin-right: 4%;
}

#login-link {
    text-decoration: none;
    color: white;
}

@media only screen and (min-width: 750px) {
    #login-button {
        font-size: 9px;
        padding: 4px 9px;
    }
}

@media only screen and (min-width: 1100px) {
    #login-button {
        font-size: 11px;
        padding: 3px 6px;
    }
}

@media only screen and (min-width: 1300px) {
    #login-button {
        font-size: 12px;
        padding: 4px 8px;
    }
}

@media only screen and (min-width: 1500px) {
    #login-button {
        font-size: 14px;
    }

    #login-logo {
        max-width: 1.35rem;
    }
}

@media only screen and (min-width: 1700px) {
    #login-button {
        font-size: 15.5px;
    }

    .header-coin-logo {
        max-width: 20px;
        max-height: 20px;
    }
}

@media only screen and (min-width: 1900px) {
    #login-button {
        font-size: 18px;
        width: 30%;
    }

    #login-logo {
        max-width: 1.5rem;
    }
}