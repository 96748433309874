#feedback_alert_box {
    position: fixed;
    top: 5%;
    width: fit-content;
    margin: 0 auto;
    left: 0;
    right: 0;
    border-radius: 10px;
    padding: 1% 2.5%;
    z-index: 100;
}

#feedback_alert_box {
    text-align: center;
}

@media only screen and (min-width: 1000px) {
    #feedback_message {
        font-size: 18px !important;
    }
}