#adminPage h4 {
    color: aqua;
    font-weight: normal;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

#match_form {
    background-color: #1F2233;
    max-width: 96.5%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1%;
    border-radius: 5px;
}

#match_form span {
    color: #7121bc;
    font-style: italic;
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 3.5px;
    padding-left: 2%;
}

#form_stream_link {
    min-width: 98%;
    margin-bottom: 1.5%;
}

#match_form_date {
    max-width: 25%;
    text-align: right;
    margin-bottom: 10px;
}

#match_form_category {
    margin-bottom: 10px;
}

#match_form input, #match_form select option, #match_form select {
    background-color: #35435E;
    border: none;
    color: rgb(255, 255, 255);
    font-style: italic;
    font-size: 12px;
    border: 1px solid #7121bc;
    border-radius: 4px;
    padding: 0.5% 1% 0.5% 1%;
}

#match_form_submit {
    background-color: rgb(2, 175, 2);
    color: white;
    min-width: 99%;
    border: none;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    border-radius: 2.5px;
    font-size: 11px;
    padding: 5px 0px;
    cursor: pointer;
}

.match_form_team_name {
    max-width: 30%;
    margin-bottom: 2.5px;
    padding: 0.5% 1% 0.5% 1%;
}

#label_for_match_form_time {
    color: white;
    font-size: 11px;
    font-style: italic;
    width: 65%;
    padding: 0.5% 0.5% 0.5% 2%;
}

#match_form_time{
    max-width: 25%;
    text-align: right;
    margin-bottom: 10px;
}

#team_names {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

.team_two_form_input {
    text-align: right;
}

#match_confirmed_label {
    font-size: 12px;
    color: white;
    padding: 0.5 1%;
    margin-bottom: 2%;
}

#match_confirmed {
    margin-bottom: 2%;
}

@media only screen and (min-width: 600px) {
    #match_form input, #match_form select option, #match_form select, #match_confirmed_label {
        font-size: 14px;
    }

    #label_for_match_form_time {
        font-size: 13px;
    }

    #match_form span {
        font-size: 12px;
    }

    #match_form_submit {
        font-size: 15px;
    }
}

@media only screen and (min-width: 750px) {
    #match_form {
        max-width: 60%;
        margin: 5% auto;
    }

    #match_form input, #match_form select option, #match_form select, #match_confirmed_label {
        font-size: 11px;
        margin-bottom: 1%;
    }

    #label_for_match_form_time, #match_form span {
        font-size: 10px;
    }

    #match_form_submit {
        font-size: 12px;
    }

    #adminPage {
        position: relative;
        top: 61px;
    }
}

@media only screen and (min-width: 1100px) {
    #match_form {
        max-width: 60%;
        margin: 5% auto;
    }

    #match_form input, #match_form select option, #match_form select, #match_confirmed_label {
        font-size: 13px;
        margin-bottom: 1%;
    }

    #label_for_match_form_time, #match_form span {
        font-size: 12px;
    }

    #match_form_submit {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1300px) {
    #match_form input, #match_form select option, #match_form select, #match_confirmed_label {
        font-size: 17px;
    }

    #label_for_match_form_time, #match_form span {
        font-size: 15px;
    }

    #match_form_submit {
        font-size: 17px;
    }

    #match_form {
        margin: 2.5% auto;
    }

    #adminPage h4 {
        font-size: 22px;
        margin: 3% auto;
    }
}

@media only screen and (min-width: 1600px) {
    #match_form input, #match_form select option, #match_form select, #match_confirmed_label {
        font-size: 18.5px;
    }

    #label_for_match_form_time, #match_form span {
        font-size: 16.5px;
    }

    #match_form_submit {
        font-size: 18.5px;
    }

    #match_form {
        margin: 2.5% auto;
    }

    #adminPage h4 {
        font-size: 23.5px;
        margin: 3% auto;
    }
}

@media only screen and (min-width: 1800px) {
    #match_form input, #match_form select option, #match_form select, #match_confirmed_label {
        font-size: 20px;
    }

    #label_for_match_form_time, #match_form span {
        font-size: 18px;
    }

    #match_form_submit {
        font-size: 20px;
    }

    #adminPage h4 {
        font-size: 25px;
    }
}