.live-stream-container, .live-streams, .stream-embed-container {
    max-width: 100%;
    text-align: center;
}

iframe {
    border-top: 1px solid red;
    border-bottom: 1px solid red;
    max-height: 500px;
    min-height: 150px;
}

@media only screen and (min-width: 750px) {
    iframe {
        min-height: 200px;
    }
}

@media only screen and (min-width: 1000px) {
    iframe {
        min-height: 250px;
    }
}

@media only screen and (min-width: 1300px) {
    iframe {
        min-height: 300px;
    }
}

@media only screen and (min-width: 1500px) {
    iframe {
        min-height: 350px;
    }
}

@media only screen and (min-width: 1700px) {
    iframe {
        min-height: 400px;
    }
}

@media only screen and (min-width: 1900) {
    iframe {
        min-height: 450px;
    }
}