#events-banner {
    display: flex;
    border-bottom: 2px solid orange;
    justify-content: start;
    padding: 4%;
    max-width: 100%;
}

#events-banner img {
    transform: rotate(-90deg);
    max-width: 12.5px;
    cursor: pointer;
}

#events-banner span {
    color: white;
    font-size: 12px;
    margin-left: 7.5%
}