#basket-review {
    background-color: #1F2233;
    font-weight: bold;
    color: white;
    max-width: 95%;
    margin: 1% auto;
    border-radius: 5px;
}

#basket-review div span img {
    max-width: 15px;
    padding-left: 5%;
}

#total-returns-span, #total-bets-span {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 500;
}

#total-bets-cost {
    font-weight: bold;
    display: flex;
    font-size: 12px;
    align-items: center;
}

#total-bets-container {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    padding: 2.5% 2.5% 0% 2.5%;
}

#total-returns-container {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    padding: 2.5%;
}

#bet-returns {
    color: #16A9CB;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: bold;
}

#basket-validation-container {
    display: flex;
    justify-content: space-around;
    padding-bottom: 2%;
}

#basket-confirm-button {
    background-color: #FA8C14;
    width: 97%;
    font-weight: 600;
    color: white;
    font-size: 13px;
    margin: 0px auto;
    border-radius: 5px;
    border: none;
    padding: 2% 0%;
    cursor: pointer;
}

@media only screen and (min-width: 600px) {
    #total-bets-span, #total-returns-span, #total-bets-cost, #bet-returns {
        font-size: 15px;
    }

    #basket-confirm-button  {
        font-size: 16px;
    }

    #basket-review div span img {
        max-width: 17.5px;
    }
}

@media only screen and (min-width: 750px) {
    #basket-review {
        background-color: #101017;
        margin-bottom: 10%;
    }

    #total-returns-container {
        font-size: 13px;
        padding: 2.5%;
        margin-bottom: 5%;
    }

    #total-bets-span, #total-bets-cost {
        font-size: 9px;
    }

    #total-returns-span, #bet-returns {
        font-size: 11px;
        font-weight: bold;
        color: white;
    }

    #basket-confirm-button {
        background-color: #16A9CB;
        font-size: 12px;
        line-height: 1.2;
        border-radius: 25px;
        padding: 2.2%;
    }

    #basket-review div span img {
        max-width: 10px;
    }
}

@media only screen and (min-width: 1100px) {
    #total-bets-span, #total-bets-cost {
        font-size: 11px;
    }

    #total-returns-span, #bet-returns {
        font-size: 12px;
    }
}

@media only screen and (min-width: 1300px) {
    #total-bets-span, #total-bets-cost {
        font-size: 13px;
    }

    #total-returns-span, #bet-returns {
        font-size: 14px;
    }

    #basket-confirm-button {
        padding: 3%;
        font-size: 14px;
    }
}

@media only screen and (min-width: 1400px) {
    #total-bets-span, #total-bets-cost {
        font-size: 14px;
    }

    #total-returns-span, #bet-returns {
        font-size: 14px;
    }

    #basket-confirm-button {
        font-size: 15px;
    }
}

@media only screen and (min-width: 1500px) {
    #total-bets-span, #total-bets-cost {
        font-size: 15px;
    }

    #total-returns-span, #bet-returns {
        font-size: 15px;
    }

    #basket-confirm-button {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1600px) {
    #total-bets-span, #total-bets-cost {
        font-size: 15.5px;
    }

    #total-returns-span, #bet-returns {
        font-size: 15.5px;
    }

    #basket-confirm-button {
        font-size: 16.5px;
    }
}

@media only screen and (min-width: 1800px) {
    #total-bets-span, #total-bets-cost {
        font-size: 18px;
    }

    #total-returns-span, #bet-returns {
        font-size: 18px;
    }

    #basket-confirm-button {
        font-size: 19px;
    }
}

@media only screen and (min-width: 1900px) {
    #total-bets-span, #total-bets-cost {
        font-size: 20px;
    }

    #total-returns-span, #bet-returns {
        font-size: 20px;
    }

    #basket-confirm-button {
        font-size: 21px;
    }
}