#basket-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-weight: 500;
    border-bottom: 1.5px solid #FA8C14;
}

#basket-banner span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    width: 70%;
    padding: 2%;
}

#basket-banner-cart {
    max-width: 35px;
    margin-right: 5%;
}

#basket-banner-bin {
    max-width: 25px;
    padding: 2.5%;
    cursor: pointer;
}

#desktop-basket-button {
    display: none;
    cursor: pointer;
}

@media only screen and (min-width: 600px) {
    #basket-banner-bin {
        max-width: 27.5px;
    }
}

@media only screen and (min-width: 750px) {
    #basket-banner-bin, .basket-item-bin {
        display: none;
    }

    #basket-banner {
        padding: 2%
    }

    #basket-banner-cart {
        max-width: 15px;
        margin-right: 5%;
    }

    #basket-banner span {
        font-size: 12px;
        width: 80%;
    }

    #desktop-basket-button {
        display: initial;
        transform: rotate(90deg);
        max-width: 12.5px;
        margin-right: 1%;
        padding-top: 5%;
    }
}

@media only screen and (min-width: 1300px) {
    #basket-banner span {
        font-size: 15px;
    }

    #desktop-basket-button {
        max-width: 17.5px;
    }

    #basket-banner-cart {
        max-width: 18px;
    }
}

@media only screen and (min-width: 1500px) {
    #basket-banner span {
        font-size: 17.5px;
    }

    #desktop-basket-button {
        max-width: 18.5px;
    }

    #basket-banner-cart {
        max-width: 19px;
        width: 19px;
    }

    .basket-cross {
        max-width: 20px;
        max-height: 20px;
        cursor: pointer;
    }
}

@media only screen and (min-width: 1800px) {
    #basket-banner span {
        font-size: 19px;
    }

    #desktop-basket-button {
        max-width: 22px;
        width: 22px;
    }

    #basket-banner-cart {
        max-width: 22px;
        width: 22px;
    }


}

@media only screen and (min-width: 1900px) {
    #basket-banner span {
        font-size: 23px;
    }

    #basket-banner-cart {
        max-width: 25px;
        width: 25px;
    }

    #desktop-basket-button {
        max-width: 25px;
        width: 25px;
    }
        
    .basket-cross {
        max-width: 24px;
        max-height: 24px;
    }
}