#header {
    background-image: url('../../images/header.png');
    background-size: cover;
    color: white;
    display: inline-flex;
    min-width: 100%;
    justify-content: space-between;
    border-bottom: 1.5px solid #2C303A;
    z-index: 99;
}

.malm-logo {
    max-width: 30px;
    max-height: 30px;
    align-self: center;
    margin-right: 4%;
}

.malm-logo {
    cursor: pointer;
}

#menu-icon {
    max-width: 35px;
    max-height: 35px;
    align-self: center;
    margin: 5% 5% 5% 15%;
}

#menu-icon:hover {
    cursor: pointer;
}

.heading-child {
    display: inline-flex;
    min-width: 50%;
    align-items: center;
}

.heading-child p {
    max-width: fit-content;
    font-weight: 600;
}

#malm-title {
    font-size: 13px;
}

#malm-title:hover {
    cursor: pointer;
}

.heading-child.right {
    margin-right: 1%;
    max-width: 40%;
    justify-content: flex-end;
}

.hamburger {
    width: 20%;
    align-self: center;
    margin-left: 7.5%;
    margin-right: 2%;
}

.coin-balance {
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 2%;
}

.header-coin-logo {
    max-width: 17.5px;
    max-height: 17.5px;
    margin-right: 2%;
}

#top-nav-items {
    display: none;
}

#top-nav-items span {
    cursor: pointer;
}

#top-nav-actions {
    display: none;
}

#top-nav-actions span {
    cursor: pointer;
}

@media only screen and (min-width: 600px) {
    #menu-icon {
        margin: 4% 4% 4% 10%;
        max-width: 24px;
        min-width: 24px;
    }

    #malm-title {
        font-size: 14px;
    }
}

@media only screen and (min-width: 750px) {
    #menu-icon {
        display: none;
    }

    #top-nav-items {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        width: 30%;
        justify-content: space-between;
        font-size: 10px;
    }

    #header {
        justify-content: space-between;
        height: 60px;
        position: fixed;
        top: 0;
    }

    .heading-child.right {
        min-width: 11%;
        margin-right: 4.5%;
    }

    .heading-child.left {
        min-width: 11%;
        margin-left: 4.5%;
        justify-content: start;
    }

    .heading-child p {
        font-size: 10px;
    }

    #malm-title {
        font-size: 9px;
    }

    .header-coin-logo {
        max-width: 15px;
        max-height: 15px;
    }

    #top-nav-actions {
        display: flex;
        width: 25%;
        justify-content: space-evenly;
        align-content: center;
        flex-wrap: wrap;
    }

    #top-nav-actions span {
        font-size: 10px;
        align-self: center;
    }

    .top-nav-action {
        font-size: 10px;
        padding: 1.5px 5px;
        border: 2px solid rgb(255, 182, 47);
        color: rgb(255, 182, 47);
    }

    .uk-flag {
        max-width: 16px;
        max-height:11px;
        align-self: center;
    }

    .french-flag {
        max-width: 15px;
        max-height: 10px;
        align-self: center;
    }

    .malm-logo {
        padding-right: 7.5%;
        margin-right: 7.5%;
        border-right: 1px solid rgb(102, 102, 102);
    }
}

@media only screen and (min-width: 900px) {
    #top-nav-items {
        justify-content: space-around;
    }

    .heading-child.left, .heading-child.right {
        min-width: 20%;
        margin: 0;
    }

    .heading-child.left {
        justify-content: end;
    }

    .heading-child.right {
        justify-content: start;
    }

    .malm-logo {
        padding-right: 5%;
        margin-right: 5%;
    }
}

@media only screen and (min-width: 1100px) {
   .header-coin-logo {
        max-width: 15px;
        max-height: 15px;
        padding-right: 10%;
        padding-left: 10%;
    }

    .heading-child p {
        font-size: 14px;
        margin-right: 5%;
    }

    #malm-title {
        font-size: 14px;
    }

    .malm-logo {
        max-width: 30px;
        max-height: 30px;
    }

    #top-nav-items {
        font-size: 12px;
    }

    #top-nav-actions span {
        font-size: 12px;
    }
}

@media only screen and (min-width: 1300px) {
    #top-nav-actions {
        width: 20%;
    }

    #top-nav-items {
        justify-content: space-evenly;
    }

    .heading-child.left, .heading-child.right {
        min-width: 23%;
    }

    #top-nav-items {
        font-size: 13.5px;
    }

    #top-nav-actions span {
        font-size: 13px;
    }

    .heading-child p {
        font-size: 14.5px;
    }
}

@media only screen and (min-width: 1400px) {
    .malm-logo {
        max-width: 35px;
        max-height: 35px;
    }

    #top-nav-actions {
        width: 20%;
    }

    #top-nav-items {
        justify-content: space-evenly;
    }

    .heading-child.left, .heading-child.right {
        min-width: 23%;
    }

    #top-nav-items {
        font-size: 14.5px;
    }

    #top-nav-actions span {
        font-size: 14px;
    }

    .heading-child p {
        font-size: 15.5px;
    }
}

@media only screen and (min-width: 1500px) {
    #malm-title, .heading-child p {
        font-size: 16.5px;
    }

    #top-nav-items {
        font-size: 15.5px;
    }
}

@media only screen and (min-width: 1600px) {
    #malm-title, .heading-child p {
        font-size: 18px;
    }

    #top-nav-items {
        font-size: 17px;
    }

    #top-nav-actions span {
        font-size: 18.5px;
    } 

    .uk-flag, .french-flag {
        max-width: 25px;
        max-height: 15px;
        width: 25px;
    }
}

@media only screen and (min-width: 1700px) {
    .malm-logo {
        max-width: 40px;
        max-height: 40px;
    }    

    #malm-title, .heading-child p {
        font-size: 18.5px;
    }

    #top-nav-items {
        font-size: 17.5px;
    }

    #top-nav-actions span {
        font-size: 19px;
    } 
}

@media only screen and (min-width: 1900px) {
    #malm-title, .heading-child p {
        font-size: 21px;
    }

    .malm-logo {
        max-width: 45px;
        max-height: 45px;
    }

    #top-nav-items {
        font-size: 19.5px;
    }

    #top-nav-actions span {
        font-size: 19.5px;
    }
}